import { ChangeDetectorRef, Component, ElementRef, Inject, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SerService } from '../../../../services/ser.service';
import { Observable, startWith, distinctUntilChanged, switchMap, map } from 'rxjs';
import { DatePipe } from '@angular/common';
import { Ser2Service } from 'src/app/services/ser2.service';
import { GoogleMap } from '@angular/google-maps';
import { styles } from 'src/app/styleMap';


interface marker {
  lat: number;
  lng: number;
  position: google.maps.LatLngLiteral;
}

@Component({
  selector: 'vex-emp-create-update',
  templateUrl: './emp-create-update.component.html',
  styleUrls: ['./emp-create-update.component.scss']
})
export class EmpCreateUpdateComponent implements OnInit {
  loading = { 1: false, 2: false, 3: false, 4: false };

  form: FormGroup;
  mode: 'create' | 'update' = 'create';

  constructor(@Inject(MAT_DIALOG_DATA) public defaults: any,
    private dialogRef: MatDialogRef<EmpCreateUpdateComponent>,
    private fb: FormBuilder,
    private snackbar: MatSnackBar,
    public service: SerService,
    public service2: Ser2Service,
    private ngZone: NgZone,
    public datepipe: DatePipe,
    private dialog: MatDialog,
    private cd: ChangeDetectorRef) {
  }

  modeStyle = '';
  stateCtrl2: FormControl;
  filteredStates$$: Observable<any[]>;
  accesos;
  roless;

  numRegex = /^-?\d*[.,]?\d{1,2}$/;
  numRegex2 = /^-?[0-9][^\.]*$/;

  ngOnInit() {
    if (this.defaults) {
      this.mode = 'update';
      var fecha1 = new Date(this.defaults.fecha);
      var fecha2 = new Date(this.defaults.fecha_ingreso);
      if (this.defaults.fecha_salida != null) {
        var fecha3 = new Date(this.defaults.fecha_salida);
      } else {
        fecha3 = null;
      }
      this.form = this.fb.group({
        id: [this.defaults.id],
        nombre_completo: [this.defaults.nombre_completo, [Validators.required]],
        fecha: [fecha1, [Validators.required]],
        cargo: [this.defaults.cargo, [Validators.required]],
        turno: [this.defaults.turno, [Validators.required]],
        telefono: [this.defaults.telefono],
        celular: [this.defaults.celular, [Validators.required]],
        direccion: [this.defaults.direccion, [Validators.required]],
        foto: [''],
        foto_ci_1: [''],
        foto_ci_2: [''],
        fecha_ingreso: [fecha2, [Validators.required]],
        fecha_salida: [fecha3],
      });
      this.image = this.defaults.foto;
      this.imageCi1 = this.defaults.foto_ci1;
      this.imageCi2 = this.defaults.foto_ci2;
    } else {

      this.stateCtrl2 = new FormControl('');
      this.filteredStates$$ = this.stateCtrl2.valueChanges.pipe(
        startWith(''),
        distinctUntilChanged(),
        switchMap(val => {
          return this.filterUni(val || '')
        })
      );
      this.defaults = {} as any;
      this.form = this.fb.group({
        nombre_completo: ['', [Validators.required]],
        fecha: ['', [Validators.required]],
        cargo: ['', [Validators.required]],
        turno: ['', [Validators.required]],
        telefono: [''],
        celular: ['', [Validators.required]],
        direccion: ['', [Validators.required]],
        foto: [''],
        foto_ci_1: [''],
        foto_ci_2: [''],
        email: ['', [Validators.required, Validators.email]],
        password: ['', [Validators.required]],
      });
    }

    if (this.defaults) {
      console.log(this.defaults);
      if (this.defaults.mode == 'sucursal') {
        this.modeStyle = 'sucursal';
        this.stateCtrl2 = new FormControl(this.defaults.data.sucursal_nombre);
        this.idSucursal = this.defaults.data.sucursales_id;
        this.filteredStates$$ = this.stateCtrl2.valueChanges.pipe(
          startWith(''),
          distinctUntilChanged(),
          switchMap(val => {
            return this.filterUni(val || '')
          })
        );
      } else if (this.defaults.mode == 'formaciones') {
        this.modeStyle = 'formaciones';
        this.getExtractos();
      } else if (this.defaults.mode == 'createForm') {
        this.modeStyle = 'createForm';
        var dt = new Date();
        this.form = this.fb.group({
          nombre: ['', [Validators.required]],
          nivel: ['', [Validators.required]],
          ano_egreso: ['', [Validators.required, Validators.max(dt.getFullYear()), Validators.min(2005)]],
          institucion: ['', [Validators.required]],
        });
      } else if (this.defaults.mode == 'editForm') {
        this.modeStyle = 'createForm';
        var dt = new Date();
        this.form = this.fb.group({
          nombre: [this.defaults.data.nombre, [Validators.required]],
          nivel: [this.defaults.data.nivel, [Validators.required]],
          ano_egreso: [this.defaults.data.ano_egreso, [Validators.required, Validators.max(dt.getFullYear())]],
          institucion: [this.defaults.data.institucion, [Validators.required]],
        });
      } else if (this.defaults.mode == 'delForm') {
        this.modeStyle = 'delForm';
      } else if (this.defaults.mode == 'accesso') {
        this.modeStyle = 'accesso';
        this.accesos = this.defaults.data.accesos[0];
        this.form = this.fb.group({
          email: [this.accesos.email, [Validators.required, Validators.email]],
          password: [''],
        });
      } else if (this.defaults.mode == 'asignar') {
        this.modeStyle = 'asignar';
        this.getRoles();
        this.accesos = this.defaults.data.accesos[0];
        this.roless = this.defaults.data.asignaciones[0];
        this.form = this.fb.group({
          rol: ['', [Validators.required]],
        });

        if (this.roless != undefined) {
          this.form.controls['rol'].setValue(this.roless[0].id)
        }
      } else if (this.defaults.mode == 'documentos') {
        this.modeStyle = 'documentos';
        this.getCertificaciones();
      } else if (this.defaults.mode == 'createCerti') {
        this.modeStyle = 'createCerti';
        this.form = this.fb.group({
          institucion: ['', Validators.required],
          puntaje: ['', [Validators.pattern(this.numRegex), Validators.max(100)]],
          tiempo_estudio: ['', [Validators.pattern(this.numRegex2)]],
          descripcion: [''],
          foto: [],
          pdf: [],
        });
      } else if (this.defaults.mode == 'editCerti') {
        this.modeStyle = 'createCerti';
        this.form = this.fb.group({
          institucion: [this.defaults.data.institucion, Validators.required],
          puntaje: [this.defaults.data.puntaje, [Validators.required, Validators.pattern(this.numRegex), Validators.max(100)]],
          tiempo_estudio: [this.defaults.data.tiempo_estudio, [Validators.pattern(this.numRegex2)]],
          descripcion: [this.defaults.data.descripcion],
          foto: [],
          pdf: [],
        });
        this.image = this.defaults.data.foto
      } else if (this.defaults.mode == 'delCerti') {
        this.modeStyle = 'delCerti';
      } else if (this.defaults.mode == 'direcciones') {
        this.modeStyle = 'direcciones';
        this.form = this.fb.group({
          direccion: [this.defaults.data.direccion, [Validators.required]],
          lat: [this.defaults.data.lat, [Validators.required]],
          lng: [this.defaults.data.lng, [Validators.required]],
        });
        this.latitude = this.defaults.data.lat;
        this.longitude = this.defaults.data.lng;
        this.markers.push({
          lat: this.latitude,
          lng: this.longitude,
          position: {
            lat: this.latitude, lng: this.longitude
          },
        });
        this.center = {
          lat: this.latitude,
          lng: this.longitude,
        };
      } else if (this.defaults.mode == 'editPerfil') {
        this.modeStyle = 'editPerfil';
        this.form = this.fb.group({
          password: ['', [Validators.minLength(6)]]
        })
      }
    }
  }


  add(i) {
    this.loading[i] = true;
    if (this.form.valid && this.valid == true && this.valid2 == true && this.valid3 == true) {
      this.service.createEmpleado(this.form.value.nombre_completo, this.datepipe.transform(this.form.value.fecha, 'yyyy-MM-dd'), this.form.value.cargo,
        '', this.form.value.turno, this.form.value.telefono, this.form.value.celular, this.form.value.direccion,
        this.form.get('foto').value, this.idSucursal, this.form.value.email, this.form.value.password, this.form.get('foto_ci_1').value,
        this.form.get('foto_ci_2').value, this.markers[0].lat, this.markers[0].lng).subscribe((data) => {
          this.message('Registrado');
          const customer = this.form.value;
          this.dialogRef.close(customer);
        }, err => {
          this.loading[i] = false;
          if (err.error.errors.celular) {
            if (err.error.errors.celular[0] == 'The celular has already been taken.') {
              this.message('This celular already exists');
            }
          } else if (err.error.errors.telefono) {
            if (err.error.errors.telefono[0] == 'The telefono has already been taken.') {
              this.message('This telefono already exists');
            }
          } else if (err.error.errors.email) {
            if (err.error.errors.email[0] == 'The email has already been taken.') {
              this.message('This email already exists');
            }
          } else {
            this.message('Error New');
            console.log(err);
          }
        });
    } else {
      this.loading[i] = false;
      this.message('Please check the data');
    }
  }

  addFormacion(i) {
    this.loading[i] = true;
    if (this.form.valid) {
      this.service.createFormaciones(this.form.value.nombre, this.form.value.nivel, this.form.value.ano_egreso,
        this.form.value.institucion, this.defaults.data.id).subscribe((data) => {
          this.message('Registrado');
          const customer = this.form.value;
          this.dialogRef.close(customer);
        }, err => {
          this.loading[i] = false;
          this.message('Error New');
          console.log(err);
        });
    } else {
      this.loading[i] = false;
      this.message('Please check the data');
    }
  }

  edit(i): void {
    this.loading[i] = true;
    if (this.form.valid) {
      if (this.form.value.fecha_salida != null) {
        this.service.editEmpleado(this.defaults.id, this.form.value.nombre_completo, this.datepipe.transform(this.form.value.fecha, 'yyyy-MM-dd'), this.form.value.cargo,
          this.datepipe.transform(this.form.value.fecha_ingreso, 'yyyy-MM-dd'), this.datepipe.transform(this.form.value.fecha_salida, 'yyyy-MM-dd'), this.form.value.turno,
          this.form.value.telefono, this.form.value.celular, this.form.value.direccion, this.form.get('foto').value, this.form.get('foto_ci_1').value,
          this.form.get('foto_ci_2').value).subscribe((data) => {
            this.message('Editado');
            const customer = this.form.value;
            customer.id = this.defaults.id;
            this.dialogRef.close(customer);
          }, err => {
            this.loading[i] = false;
            if (err.error.error) {
              if (err.error.error == "Se debe indicar al menos un valor diferente para actualizar") {
                this.message('Se debe indicar al menos un valor diferente');
                console.log(err);
              }
            } else
              if (err.error.errors) {
                if (err.error.errors.celular) {
                  if (err.error.errors.celular[0] == 'The celular has already been taken.') {
                    this.message('This celular already exists');
                  }
                } else if (err.error.errors.telefono) {
                  if (err.error.errors.telefono[0] == 'The telefono has already been taken.') {
                    this.message('This telefono already exists');
                  }
                }
              }
              else {
                this.message('Error New');
                console.log(err);
              }
          });
      } else {
        this.service.editEmpleado(this.defaults.id, this.form.value.nombre_completo, this.datepipe.transform(this.form.value.fecha, 'yyyy-MM-dd'), this.form.value.cargo,
          this.datepipe.transform(this.form.value.fecha_ingreso, 'yyyy-MM-dd'), '', this.form.value.turno,
          this.form.value.telefono, this.form.value.celular, this.form.value.direccion, this.form.get('foto').value, this.form.get('foto_ci_1').value,
          this.form.get('foto_ci_2').value).subscribe((data) => {
            this.message('Editado');
            const customer = this.form.value;
            customer.id = this.defaults.id;
            this.dialogRef.close(customer);
          }, err => {
            this.loading[i] = false;
            if (err.error.error) {
              if (err.error.error == "Se debe indicar al menos un valor diferente para actualizar") {
                this.message('Se debe indicar al menos un valor diferente');
                console.log(err);
              }
            } else
              if (err.error.errors) {
                if (err.error.errors.celular) {
                  if (err.error.errors.celular[0] == 'The celular has already been taken.') {
                    this.message('This celular already exists');
                  }
                } else if (err.error.errors.telefono) {
                  if (err.error.errors.telefono[0] == 'The telefono has already been taken.') {
                    this.message('This telefono already exists');
                  }
                }
              }
              else {
                this.message('Error New');
                console.log(err);
              }
          });
      }
    } else {
      this.loading[i] = false;
      this.message('Please check the data');
    }
  }

  editFormacion(i): void {
    this.loading[i] = true;
    if (this.form.valid) {
      this.service.editFormaciones(this.defaults.data.id, this.form.value.nivel, this.form.value.ano_egreso,
        this.form.value.institucion, this.form.value.nombre).subscribe((data) => {
          this.message('Editado');
          const customer = this.form.value;
          customer.id = this.defaults.id;
          this.dialogRef.close(customer);
        }, err => {
          this.loading[i] = false;
          if (err.error.error) {
            if (err.error.error == "Se debe indicar al menos un valor diferente para actualizar") {
              this.message('Se debe indicar al menos un valor diferente');
              console.log(err);
            }
          } else {
            this.message('Error New');
            console.log(err);
          }
        });
    } else {
      this.loading[i] = false;
      this.message('Please check the data');
    }
  }

  editDireccion(i): void {
    this.loading[i] = true;
    if (this.stateCtrl2.valid) {
      this.service.editEmpleadoSucursal(this.defaults.data.id, this.idSucursal).subscribe((data) => {
        this.message('Editado');
        const customer = this.form.value;
        customer.id = this.defaults.id;
        this.dialogRef.close(customer);
      }, err => {
        this.loading[i] = false;
        if (err.error.error) {
          if (err.error.error == "Se debe indicar al menos un valor diferente para actualizar") {
            this.message('Se debe indicar al menos un valor diferente');
            console.log(err);
          } else {
            this.message('Error New');
            console.log(err);
          }
        }
      });
    } else {
      this.loading[i] = false;
      this.message('Please check the data');
    }
  }

  editDireccion2(i): void {
    this.loading[i] = true;
    if (this.form.valid && this.latitude && this.longitude) {
      this.service.editDireccionEmpleado(this.defaults.data.id, this.latitude, this.longitude).subscribe((data) => {
        this.message('Editado');
        const customer = this.form.value;
        customer.id = this.defaults.id;
        this.dialogRef.close(customer);
      }, err => {
        this.loading[i] = false;
        if (err.error.error) {
          if (err.error.error == "Se debe indicar al menos un valor diferente para actualizar") {
            this.message('Se debe indicar al menos un valor diferente');
            console.log(err);
          } else {
            this.message('Error New');
            console.log(err);
          }
        }
      });
    } else {
      this.loading[i] = false;
      this.message('Please check the data');
    }
  }

  isCreateMode() {
    return this.mode === 'create';
  }

  isUpdateMode() {
    return this.mode === 'update';
  }

  message(m): void {
    this.snackbar.open(m, 'Cerrar', {
      duration: 4000
    });
  }

  keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 46 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  keyPressNumbers2(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  pdf = '';
  validPdf = true;
  uploadPdf(event) {
    const file = (event.target as HTMLInputElement).files[0];
    if (file.type == 'application/pdf') {
      this.form.patchValue({
        pdf: file
      });
      this.form.get('pdf').updateValueAndValidity();
      console.log(file);
      this.pdf = file.name;
      this.validPdf = true;
    } else {
      this.validPdf = false;
    }
  }

  image = '';
  image2 = '';
  valid = true;
  uploadFile(event) {
    const reader = new FileReader();
    const [file2] = event.target.files;
    reader.readAsDataURL(file2);
    reader.onload = (e: any) => {
      this.image = e.target.result;
      this.image2 = e.target.result;
    }

    const file = (event.target as HTMLInputElement).files[0];
    this.form.patchValue({
      foto: file
    });
    this.form.get('foto').updateValueAndValidity();

    if (file.type == 'image/jpeg' || file.type == 'image/png' || file.type == 'image/jpg') {
      this.valid = true;
    } else {
      this.valid = false;
    }
  }

  imageCi1 = '';
  valid2 = true;
  uploadFile2(event) {
    const reader = new FileReader();
    const [file2] = event.target.files;
    reader.readAsDataURL(file2);
    reader.onload = (e: any) => {
      this.imageCi1 = e.target.result;
    }

    const file = (event.target as HTMLInputElement).files[0];
    this.form.patchValue({
      foto_ci_1: file
    });
    this.form.get('foto_ci_1').updateValueAndValidity();

    if (file.type == 'image/jpeg' || file.type == 'image/png' || file.type == 'image/jpg') {
      this.valid2 = true;
    } else {
      this.valid2 = false;
    }
  }

  imageCi2 = '';
  valid3 = true;
  uploadFile3(event) {
    const reader = new FileReader();
    const [file2] = event.target.files;
    reader.readAsDataURL(file2);
    reader.onload = (e: any) => {
      this.imageCi2 = e.target.result;
    }

    const file = (event.target as HTMLInputElement).files[0];
    this.form.patchValue({
      foto_ci_2: file
    });
    this.form.get('foto_ci_2').updateValueAndValidity();

    if (file.type == 'image/jpeg' || file.type == 'image/png' || file.type == 'image/jpg') {
      this.valid3 = true;
    } else {
      this.valid3 = false;
    }
  }

  filterUni(nombre: string): Observable<any[]> {
    return this.service.getSucursales()
      .pipe(
        map(response => response.data.filter(option => {
          return option.nombre.toLowerCase().indexOf(nombre.toLowerCase()) === 0
        }))
      );
  }

  idSucursal: any;
  reset(): void {
    this.stateCtrl2.reset();
    this.idSucursal = '';
  }

  sel2(e): void {
    this.idSucursal = e.option.id.id;
  }

  formaciones: any;
  getExtractos(): void {
    this.service.getFormaciones(this.defaults.data.id).subscribe((data => {
      if (data.data instanceof Array) {
        this.formaciones = data.data;
        console.log(this.formaciones);
      }
    }));
  }

  bcreateFormacion(data): void {
    this.dialog.open(EmpCreateUpdateComponent, {
      data: {
        mode: 'createForm', data: data
      },
      width: '500px',
    }).afterClosed().subscribe(data => {
      if (data) {
        this.getExtractos();
      }
    });
  }

  beditFormacion(data): void {
    this.dialog.open(EmpCreateUpdateComponent, {
      data: {
        mode: 'editForm', data: data
      },
      width: '500px',
    }).afterClosed().subscribe(data => {
      if (data) {
        this.getExtractos();
      }
    });
  }

  bdeleteFormacion(data): void {
    this.dialog.open(EmpCreateUpdateComponent, {
      data: {
        mode: 'delForm', data: data
      },
      width: '400px',
    }).afterClosed().subscribe(data => {
      if (data) {
        this.getExtractos();
      }
    });
  }

  deleteFormacion(answer: string, i) {
    this.loading[i] = true;
    if (answer == 'Si') {
      this.service.deleteFormacion(this.defaults.data.id).subscribe(data => {
        this.message('Eliminado')
        this.dialogRef.close(answer);
        this.loading[i] = false;
      }, err => {
        this.message("No se puede borrar");
        console.log(err);
        this.loading[i] = false;
      });
    }
  }

  editAccesos(i): void {
    this.loading[i] = true;
    if (this.form.valid) {
      this.service.editAccesos(this.accesos.id, this.form.value.email, this.form.value.password).subscribe((data) => {
        this.message('Editado');
        const customer = this.form.value;
        customer.id = this.defaults.id;
        this.dialogRef.close(customer);
      }, err => {
        this.loading[i] = false;
        if (err.error.error) {
          if (err.error.error == "Se debe indicar al menos un valor diferente para actualizar") {
            this.message('Se debe indicar al menos un valor diferente');
            console.log(err);
          }
        } else if (err.error.errors.email) {
          if (err.error.errors.email[0] == 'The email has already been taken.') {
            this.message('This email already exists');
          }
        } else {
          this.message('Error New');
          console.log(err);
        }
      });
    } else {
      this.loading[i] = false;
      this.message('Please check the data');
    }
  }

  roles: any;
  getRoles(): void {
    this.service.getRoles().subscribe((data => {
      this.roles = data.data;
    }))
  }

  certificaciones: any;
  getCertificaciones(): void {
    this.certificaciones = [];
    this.service2.getCertificados(this.defaults.data.id).subscribe((data => {
      if (data instanceof Array) {
        this.certificaciones = data;
      }
    }))
  }

  asignarUserRol(i): void {
    this.loading[i] = true;
    if (this.form.valid) {
      this.service.asignarUserRol(this.accesos.id, this.form.value.rol).subscribe((data) => {
        this.message('Asignado');
        const customer = this.form.value;
        customer.id = this.defaults.id;
        this.dialogRef.close(customer);
      }, err => {
        this.loading[i] = false;
        this.message('Error New');
        console.log(err);
      });
    } else {
      this.loading[i] = false;
      this.message('Please check the data');
    }
  }

  addBCertificacdp(data): void {
    this.dialog.open(EmpCreateUpdateComponent, {
      data: {
        mode: 'createCerti', data: data
      },
      width: '650px',
    }).afterClosed().subscribe((data: any) => {
      if (data) {
        this.getCertificaciones();
      }
    });
  }

  addCertificacion(i) {
    this.loading[i] = true;
    if (this.form.valid && this.valid == true) {
      this.service2.createCertificacion(this.form.value.descripcion, this.form.value.tiempo_estudio, this.image,
        this.form.value.puntaje, this.form.value.institucion, this.defaults.data.data.id, this.form.get('pdf').value).subscribe((data) => {
          this.message('Registrado');
          const customer = this.form.value;
          this.dialogRef.close(customer);
        }, err => {
          this.loading[i] = false;
          this.message('Error New');
          console.log(err);
        });
    } else {
      this.loading[i] = false;
      this.message('Please check the data');
    }
  }

  addBEdtiCert(data): void {
    this.dialog.open(EmpCreateUpdateComponent, {
      data: {
        mode: 'editCerti', data: data
      },
      width: '650px',
    }).afterClosed().subscribe((data: any) => {
      if (data) {
        this.getCertificaciones();
      }
    });
  }

  editCertificacion(i) {
    this.loading[i] = true;
    if (this.form.valid && this.valid == true) {
      this.service2.editCertificacion(this.defaults.data.id, this.form.value.descripcion, this.form.value.tiempo_estudio, this.image2,
        this.form.value.puntaje, this.form.value.institucion, this.form.get('pdf').value).subscribe((data) => {
          this.message('Editado');
          const customer = this.form.value;
          this.dialogRef.close(customer);
        }, err => {
          this.loading[i] = false;
          this.message('Error New');
          console.log(err);
        });
    } else {
      this.loading[i] = false;
      this.message('Please check the data');
    }
  }

  delBCert(data): void {
    this.dialog.open(EmpCreateUpdateComponent, {
      data: {
        mode: 'delCerti', data: data
      },
      width: '450px',
    }).afterClosed().subscribe((data: any) => {
      if (data) {
        this.getCertificaciones();
      }
    });
  }

  delCertificacion(i) {
    this.loading[i] = true;
    this.service2.deleteCertificacion(this.defaults.data.id).subscribe((data) => {
      this.message('Eliminado');
      const customer = this.form.value;
      this.dialogRef.close(customer);
    }, err => {
      this.loading[i] = false;
      this.message('Error New');
      console.log(err);
    });

  }

  options: google.maps.MapOptions = {
    mapTypeControl: false,
    streetViewControl: false,
    clickableIcons: false,
    gestureHandling: 'cooperative',
    styles: styles
  }
  zoom = 12;
  center: google.maps.LatLngLiteral = {
    lat: -17.4105812,
    lng: -66.163803,
  }
    
  @ViewChild('search')
  public searchElementRef!: ElementRef;
  @ViewChild(GoogleMap)
  public map!: GoogleMap;
  latitude!: any;
  longitude!: any;
  markers: marker[] = [];

  ngAfterViewInit(): void {
    if (this.isCreateMode()) {
      new google.maps.places.Autocomplete(
        this.searchElementRef.nativeElement
      );
    }
    if (this.defaults) {
      if (this.defaults.mode == 'direcciones') {
        new google.maps.places.Autocomplete(
          this.searchElementRef.nativeElement
        );
      }
    }
  }

  onKeyUpEvent(event: any) {
    let autocomplete = new google.maps.places.Autocomplete(
      this.searchElementRef.nativeElement
    );
    autocomplete.addListener('place_changed', () => {
      const geocoder = new google.maps.Geocoder();


      this.ngZone.run(() => {
        let place: google.maps.places.PlaceResult = autocomplete.getPlace();
        console.log(place);
        this.latitude = place.geometry.location?.lat();
        this.longitude = place.geometry.location?.lng();
        this.center = {
          lat: this.latitude,
          lng: this.longitude,
        };

        this.markers = [];
        this.markers.push({
          lat: this.latitude,
          lng: this.longitude,
          position: {
            lat: this.latitude, lng: this.longitude
          },
        });
        geocoder.geocode({ location: this.markers[0].position })
          .then((res) => {
            console.log(res);
            this.form.controls['direccion'].setValue('N/A');
            this.latitude = this.markers[0].lat;
            this.longitude = this.markers[0].lng;
            for (let i = 0; i < res.results.length; i++) {
              if (res.results[i].types[0] === "postal_code") {
                let address_components = res.results[i].address_components;
                for (let j = 0; j < address_components.length; j++) {
                  if (address_components[j].types[0] === "postal_code") {
                    let googleGeocodePostalCode = address_components[j].long_name;
                    console.log(googleGeocodePostalCode, 'postal');
                  }
                }
              }
            }

            var numero = 'S/N';
            var calle;
            let address_components = res.results[0].address_components;
            for (let j = 0; j < address_components.length; j++) {

              if (address_components[j].types[0] === "route") {
                console.log(address_components[j].long_name, 'nombre_calle');
                calle = address_components[j].long_name;
                this.form.controls['direccion'].setValue(calle + ', ' + numero);
                // this.form.controls['direccion'].setValue(calle + ', ' + numero);
              }
            }
          })
      });
    });

  }

  eventHandler(event: any) {
    this.dropMarker(event)
  }

  dropMarker(event: any) {
    this.markers = [];
    this.markers.push({
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
      position: {
        lat: event.latLng.lat(), lng: event.latLng.lng()
      },
    })

    const geocoder = new google.maps.Geocoder();

    geocoder.geocode({ location: this.markers[0].position })
      .then((res) => {
        console.log(res);
        this.latitude = this.markers[0].lat;
        this.longitude = this.markers[0].lng;
        this.form.controls['direccion'].setValue(res.results[0].formatted_address);

        var numero = 'S/N';
        var calle;
        let address_components = res.results[0].address_components;
        for (let j = 0; j < address_components.length; j++) {
          if (address_components[j].types[0] === "route") {
            console.log(address_components[j].long_name, 'nombre_calle');
            calle = address_components[j].long_name;
            this.form.controls['direccion'].setValue(calle + ', ' + numero);
            // this.form.controls['direccion'].setValue(calle + ', ' + numero);
          }
        }
      });
  }

  editPerfil(i): void {
    this.loading[i] = true;
    if (this.form.valid) {
      this.service.editAccesos(localStorage.getItem('user'), '', this.form.value.password).subscribe((data) => {
        this.message('Editado');
        const customer = this.form.value;
        customer.id = this.defaults.id;
        this.dialogRef.close(customer);
      }, err => {
        this.loading[i] = false;
        if (err.error.error) {
          if (err.error.error == "Se debe indicar al menos un valor diferente para actualizar") {
            this.message('Se debe indicar al menos un valor diferente');
            console.log(err);
          }
        } else if (err.error.errors.email) {
          if (err.error.errors.email[0] == 'The email has already been taken.') {
            this.message('El email ya existe');
          }
        } else {
          this.message('Error New');
          console.log(err);
        }
      });
    } else {
      this.loading[i] = false;
      this.message('Please check the data');
    }
  }

}
