import { ChangeDetectorRef, Component, ElementRef, Inject, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SerService } from '../../../../services/ser.service';
import { GoogleMap } from '@angular/google-maps';
import { styles } from 'src/app/styleMap';
import { Ser2Service } from 'src/app/services/ser2.service';
import { fadeInRight400ms } from 'src/@vex/animations/fade-in-right.animation';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { scaleFadeIn400ms } from 'src/@vex/animations/scale-fade-in.animation';
import { scaleIn400ms } from 'src/@vex/animations/scale-in.animation';
import { stagger40ms } from 'src/@vex/animations/stagger.animation';
import { ArticuloCreateUpdateComponent } from '../../articulos/articulo-create-update/articulo-create-update.component';
import { Ser3Service } from 'src/app/services/ser3.service';

interface marker {
  lat: number;
  lng: number;
  position: google.maps.LatLngLiteral;
}

@Component({
  selector: 'vex-almacen-create-update',
  templateUrl: './almacen-create-update.component.html',
  styleUrls: ['./almacen-create-update.component.scss'],
  animations: [
    scaleIn400ms,
    fadeInRight400ms,
    stagger40ms,
    fadeInUp400ms,
    scaleFadeIn400ms
  ]
})
export class AlmacenCreateUpdateComponent implements OnInit {

  loading = { 1: false, 2: false, 3: false, 4: false };

  form: FormGroup;
  mode: 'create' | 'update' = 'create';

  constructor(@Inject(MAT_DIALOG_DATA) public defaults: any,
    private dialogRef: MatDialogRef<AlmacenCreateUpdateComponent>,
    private fb: FormBuilder,
    private snackbar: MatSnackBar,
    public service: Ser2Service,
    public service2: SerService,
    public service3: Ser3Service,
    private ngZone: NgZone,
    private dialog: MatDialog,
    private cd: ChangeDetectorRef) {
  }

  showMap = false;

  options: google.maps.MapOptions = {
    mapTypeControl: false,
    streetViewControl: false,
    clickableIcons: false,
    gestureHandling: 'cooperative',
    styles: styles
  }
  zoom = 10;
  center!: google.maps.LatLngLiteral;

  @ViewChild('search')
  public searchElementRef!: ElementRef;
  @ViewChild(GoogleMap)
  public map!: GoogleMap;
  latitude!: any;
  longitude!: any;
  markers: marker[] = [];

  modeStyle = '';
  color = '';

  ngOnInit() {
    if (this.defaults) {
      this.mode = 'update';
      this.form = this.fb.group({
        id: [this.defaults.id],
        nombre: [this.defaults.nombre, [Validators.required]],
        descripcion: [this.defaults.descripcion, [Validators.required]],
        tipo: [this.defaults.tipo, [Validators.required]],
        sucursal: [this.defaults.sucursal_id, [Validators.required]],
      });
      this.getSusucursales();
    } else {
      this.defaults = {} as any;
      this.form = this.fb.group({
        direccion: [''],
        direccion_string: ['', [Validators.required]],
        lat: ['', [Validators.required]],
        lng: ['', [Validators.required]],
        nombre: ['', [Validators.required]],
        descripcion: ['', [Validators.required]],
        tipo: ['', [Validators.required]],
        sucursal: ['', [Validators.required]],
      });

      this.getSusucursales();

      navigator.geolocation.getCurrentPosition((position) => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
      });
    }

    if (this.defaults) {
      console.log(this.defaults);
      if (this.defaults.mode == 'delete') {
        this.modeStyle = 'delAlmacen';
      } else if (this.defaults.mode == 'articulos') {
        this.modeStyle = 'articulos'
        this.getArticulos();
      } else if (this.defaults.mode == 'createDetalle') {
        this.modeStyle = 'createDetalle';
        this.form = this.fb.group({
          descripcion: ['', [Validators.required]],
          tipo: [1, [Validators.required]],
        });
      } else if (this.defaults.mode == 'editDetalle') {
        this.modeStyle = 'createDetalle';
        console.log('tipo recibido', this.defaults.data.tipo);
        this.form = this.fb.group({
          descripcion: [this.defaults.data.descripcion, [Validators.required]],
          tipo: [ this.defaults.data.tipo, [Validators.required]],
        });
      } else if (this.defaults.mode == 'deleteDetalle') {
        this.modeStyle = 'deleteDetalle';
      } else if (this.defaults.mode == 'createConcepto') {
        this.modeStyle = 'createConcepto';
        this.form = this.fb.group({
          descripcion: ['', [Validators.required]],
        });
      } else if (this.defaults.mode == 'editConcepto') {
        this.modeStyle = 'createConcepto';
        this.form = this.fb.group({
          descripcion: [this.defaults.data.descripcion, [Validators.required]],
        });
      } else if (this.defaults.mode == 'deleteConcepto') {
        this.modeStyle = 'deleteConcepto';
      }
    }
  }

  add(i) {
    this.loading[i] = true;
    if (this.form.valid) {
      this.service.createAlmcenes(this.form.value.nombre, this.form.value.descripcion, this.form.value.direccion_string,
        this.form.value.lat, this.form.value.lng, this.form.value.tipo, this.form.value.sucursal).subscribe((data) => {
          this.message('Registrado');
          const customer = this.form.value;
          this.dialogRef.close(customer);
        }, err => {
          this.loading[i] = false;
          this.message('Error New');
          console.log(err);
        });
    } else {
      this.loading[i] = false;
      this.message('Please check the data');
    }
  }


  edit(i): void {
    this.loading[i] = true;
    if (this.form.valid) {
      this.service.updateAlmcenes(this.defaults.id, this.form.value.nombre, this.form.value.descripcion, '', '', '', this.form.value.tipo,
        this.form.value.sucursal).subscribe((data) => {
          this.message('Editado');
          const customer = this.form.value;
          customer.id = this.defaults.id;
          this.dialogRef.close(customer);
        }, err => {
          this.loading[i] = false;
          if (err.error.error) {
            if (err.error.error == "Se debe indicar al menos un valor diferente para actualizar") {
              this.message('Se debe indicar al menos un valor diferente');
              console.log(err);
            } else {
              this.message('Error New');
              console.log(err);
            }
          }
        });
    } else {
      this.loading[i] = false;
      this.message('Please check the data');
    }
  }

  editDireccion(i): void {
    // this.loading[i] = true;
    // if (this.form.valid) {
    //   this.service.editSucursal2(this.defaults.data.id, this.form.value.departamento, this.form.value.estado, this.form.value.calle,
    //     this.form.value.lat, this.form.value.lng).subscribe((data) => {
    //       this.message('Editado');
    //       const customer = this.form.value;
    //       customer.id = this.defaults.id;
    //       this.dialogRef.close(customer);
    //     }, err => {
    //       this.loading[i] = false;
    //       if (err.error.error) {
    //         if (err.error.error == "Se debe indicar al menos un valor diferente para actualizar") {
    //           this.message('Se debe indicar al menos un valor diferente');
    //           console.log(err);
    //         } else {
    //           this.message('Error New');
    //           console.log(err);
    //         }
    //       }
    //     });
    // } else {
    //   this.loading[i] = false;
    //   this.message('Please check the data');
    // }
  }


  isCreateMode() {
    return this.mode === 'create';
  }

  isUpdateMode() {
    return this.mode === 'update';
  }

  message(m): void {
    this.snackbar.open(m, 'Cerrar', {
      duration: 4000
    });
  }

  visible = false;
  inputType = 'password';


  keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  change(): void {
    if (this.showMap == true) {
      this.showMap = false;
    } else {
      this.showMap = true;
    }
  }
  ngAfterViewInit(): void {
    if (this.isCreateMode()) {
      new google.maps.places.Autocomplete(
        this.searchElementRef.nativeElement
      );
    }
    if (this.defaults) {
      if (this.defaults.mode == 'direcciones') {
        new google.maps.places.Autocomplete(
          this.searchElementRef.nativeElement
        );
      }
    }
  }
  sucrusales;
  getSusucursales() {
    this.service2.getSucursales().subscribe((data) => {
      this.sucrusales = data.data;
      console.log(data.data);
    })
  }

  onKeyUpEvent(event: any) {
    let autocomplete = new google.maps.places.Autocomplete(
      this.searchElementRef.nativeElement
    );
    autocomplete.addListener('place_changed', () => {
      const geocoder = new google.maps.Geocoder();


      this.ngZone.run(() => {
        let place: google.maps.places.PlaceResult = autocomplete.getPlace();
        console.log(place);
        this.latitude = place.geometry.location?.lat();
        this.longitude = place.geometry.location?.lng();
        this.center = {
          lat: this.latitude,
          lng: this.longitude,
        };

        this.markers = [];
        this.markers.push({
          lat: this.latitude,
          lng: this.longitude,
          position: {
            lat: this.latitude, lng: this.longitude
          },
        });
        geocoder.geocode({ location: this.markers[0].position })
          .then((res) => {
            console.log(res);
            this.form.controls['calle'].setValue('N/A');
            this.form.controls['lat'].setValue(this.markers[0].lat);
            this.form.controls['lng'].setValue(this.markers[0].lng);
            for (let i = 0; i < res.results.length; i++) {
              if (res.results[i].types[0] === "postal_code") {
                let address_components = res.results[i].address_components;
                for (let j = 0; j < address_components.length; j++) {
                  if (address_components[j].types[0] === "postal_code") {
                    let googleGeocodePostalCode = address_components[j].long_name;
                    console.log(googleGeocodePostalCode, 'postal');
                  }
                }
              }
            }

            var numero = 'S/N';
            var ciudad = 'N/A';
            var calle;
            let address_components = res.results[0].address_components;
            for (let j = 0; j < address_components.length; j++) {
              if (address_components[j].types[0] === "street_number") {
                console.log(address_components[j].long_name, 'numero_calle');
                numero = address_components[j].long_name;
              }

              if (address_components[j].types[0] === "route") {
                console.log(address_components[j].long_name, 'nombre_calle');
                calle = address_components[j].long_name;
              }

              if (address_components[j].types[0] === "administrative_area_level_1") {
                console.log(address_components[j].long_name, 'estado');
              }

              if (address_components[j].types[0] === "political") {
                console.log(address_components[j].long_name, 'ciudad');
                ciudad = address_components[j].long_name;
              }

              if (address_components[j].types[0] === "country") {
                console.log(address_components[j].long_name, 'pais');
                // this.form.controls['pais'].setValue(address_components[j].long_name);
              }
            }
          })
      });
    });

  }

  eventHandler(event: any) {
    this.dropMarker(event)
  }

  dropMarker(event: any) {
    this.markers = [];
    this.markers.push({
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
      position: {
        lat: event.latLng.lat(), lng: event.latLng.lng()
      },
    })

    const geocoder = new google.maps.Geocoder();

    geocoder.geocode({ location: this.markers[0].position })
      .then((res) => {
        console.log(res);
        this.form.controls['lat'].setValue(this.markers[0].lat);
        this.form.controls['lng'].setValue(this.markers[0].lng);
        this.form.controls['direccion_string'].setValue(res.results[0].formatted_address);
        for (let i = 0; i < res.results.length; i++) {
          if (res.results[i].types[0] === "postal_code") {
            let address_components = res.results[i].address_components;
            for (let j = 0; j < address_components.length; j++) {
              if (address_components[j].types[0] === "postal_code") {
                let googleGeocodePostalCode = address_components[j].long_name;
                console.log(googleGeocodePostalCode, 'postal');
              }
            }
          }
        }

        var numero = 'S/N';
        var calle;
        let address_components = res.results[0].address_components;
        for (let j = 0; j < address_components.length; j++) {
          if (address_components[j].types[0] === "route") {
            console.log(address_components[j].long_name, 'nombre_calle');
            calle = address_components[j].long_name;
            this.form.controls['direccion_string'].setValue(calle + ', ' + numero);
            this.form.controls['direccion_string'].setValue(calle + ', ' + numero);
          }
        }
      });
  }

  changeComplete(e) {
    this.color = e.color.hex;
  }

  close(answer: string, i) {
    this.loading[i] = true;
    if (answer == 'Si') {
      this.service.deleteAlmcene(this.defaults.data.id).subscribe(data => {
        this.message('Eliminado')
        this.dialogRef.close(answer);
        this.loading[i] = false;
      }, err => {
        this.message("No se puede borrar");
        console.log(err);
        this.loading[i] = false;
      });
    }
  }

  articulos = [];
  getArticulos() {
    this.service.getArticulosAlmacen(this.defaults.data.id).subscribe((data) => {
      if (data instanceof Array) {
        this.articulos = data;
      }
    })
  }

  detailArticulo(data): void {
    this.dialog.open(ArticuloCreateUpdateComponent, {
      data: {
        mode: 'detalleArticulo', data: data
      },
      width: '800px',
    });
  }

  addCuenta(i) {
    this.loading[i] = true;
    if (this.form.valid) {
      this.service.createCuenta(this.form.value).subscribe((data) => {
        this.message('Registrado');
        const customer = this.form.value;
        this.dialogRef.close(customer);
      }, err => {
        this.loading[i] = false;
        this.message('Error New');
        console.log(err);
      });
    } else {
      this.loading[i] = false;
      this.message('Please check the data');
    }
  }

  editCuenta(i) {
    this.loading[i] = true;
    if (this.form.valid) {
      this.service.editCuenta(this.form.value, this.defaults.data.id).subscribe((data) => {
        this.message('Editado');
        const customer = this.form.value;
        this.dialogRef.close(customer);
      }, err => {
        this.loading[i] = false;
        this.message('Error New');
        console.log(err);
      });
    } else {
      this.loading[i] = false;
      this.message('Please check the data');
    }
  }

  close2(answer: string, i) {
    this.loading[i] = true;
    if (answer == 'Si') {
      this.service.deleteCuenta(this.defaults.data.id).subscribe(data => {
        this.message('Eliminado')
        this.dialogRef.close(answer);
        this.loading[i] = false;
      }, err => {
        this.message("No se puede borrar");
        console.log(err);
        this.loading[i] = false;
      });
    }
  }

  showPopover: boolean[] = [false, false, false];

  togglePopover(index: number) {
    this.showPopover[index] = !this.showPopover[index];
  }

  onSearchChange(e) {
    this.service3.productosAlmacen(this.defaults.data.id, e.target.value).subscribe((data) => {
      if (data instanceof Array) {
        this.articulos = data;
      } else {
        this.articulos = [];
      }
    });
  }

  addConcepto(i) {
    this.loading[i] = true;
    if (this.form.valid) {
      this.service3.createConceptoGastos(this.form.value.descripcion).subscribe((data) => {
        this.message('Registrado');
        const customer = this.form.value;
        this.dialogRef.close(customer);
      }, err => {
        this.loading[i] = false;
        this.message('Error New');
        console.log(err);
      });
    } else {
      this.loading[i] = false;
      this.message('Please check the data');
    }
  }

  editConcepto(i) {
    this.loading[i] = true;
    if (this.form.valid) {
      this.service3.editConceptoGastos(this.form.value.descripcion, this.defaults.data.id).subscribe((data) => {
        this.message('Editado');
        const customer = this.form.value;
        this.dialogRef.close(customer);
      }, err => {
        this.loading[i] = false;
        this.message('Error New');
        console.log(err);
      });
    } else {
      this.loading[i] = false;
      this.message('Please check the data');
    }
  }

  close3(answer: string, i) {
    this.loading[i] = true;
    if (answer == 'Si') {
      this.service3.deleteConceptoGastos(this.defaults.data.id).subscribe(data => {
        this.message('Eliminado')
        this.dialogRef.close(answer);
        this.loading[i] = false;
      }, err => {
        this.message("No se puede borrar");
        console.log(err);
        this.loading[i] = false;
      });
    }
  }
}
