import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { EncrDecrService } from './encr-decr-service.service';
import { Observable, tap, of, catchError, throwError } from 'rxjs';
import { DataResponse } from '../shared/models/response/data-response';

const httpHeaders = {
  headers: new HttpHeaders(environment.apiConfig.headersFormData),
  reportProgress: true
}
const path = environment.apiConfig.path;
@Injectable({
  providedIn: 'root'
})

@Injectable({
  providedIn: 'root'
})
export class Ser3Service {
  httpOptions: { headers: HttpHeaders; };
  urls: string;
  token: string;


  constructor(private http: HttpClient, private EncrDecr: EncrDecrService) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Authorization': 'Bearer ' + this.token
      }),
    };
  }

  getVentas(sucursal_id): Observable<any> {
    this.urls = path + "/ventas";

    let params = new HttpParams();
    params = params.append('sucursal_id', sucursal_id);

    return this.http.get<DataResponse>(`${this.urls}`, {
      headers: new HttpHeaders(environment.apiConfig.headers),
      reportProgress: true,
      params: params
    });
  }

  createVenta(fecha, hora, tipo_documento, numero_documento, razon_social, cliente_id, nuevo_precio, justificacion_precio, detalleVenta, sucursal_id): Observable<any> {
    this.urls = path + "/ventas/create";

    var datoaEnviar = {
      "fecha": fecha,
      "hora": hora,
      "tipo_documento": tipo_documento,
      "numero_documento": numero_documento,
      "razon_social": razon_social,
      "cliente_id": cliente_id,
      "nuevo_precio": nuevo_precio,
      "justificacion_precio": justificacion_precio,
      "detalleVenta": detalleVenta,
      "sucursal_id": sucursal_id,
    }
    return this.http.post(this.urls, datoaEnviar, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  deleteVenta(id): Observable<any> {
    this.urls = path + "/ventas/delete/" + id;
    return this.http.delete(this.urls, this.httpOptions)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  //Saldo inicial
  getGastoMenor(sucursal_id): Observable<any> {
    this.urls = path + "/gastos?sucursal_id=" + sucursal_id;

    return this.http.get(this.urls, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  createGastoMenor(responsable, acreedor, monto_destinado, sucursal_id): Observable<any> {
    this.urls = path + "/gastos/create";

    var datoaEnviar = {
      "responsable": responsable,
      "acreedor": acreedor,
      "monto_destinado": monto_destinado,
      "sucursal_id": sucursal_id,
    }
    return this.http.post(this.urls, datoaEnviar, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  editGastoMenor(responsable, acreedor, monto_destinado, id): Observable<any> {
    this.urls = path + "/gastos/update/" + id;

    var datoaEnviar = {
      "responsable": responsable,
      "acreedor": acreedor,
      "monto_destinado": monto_destinado,
    }
    return this.http.put(this.urls, datoaEnviar, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  deleteGastoMenor(id): Observable<any> {
    this.urls = path + "/gastos/delete/" + id;

    return this.http.delete(this.urls, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  productosEjecutados(sub_categoria_id, nombre, sucursal_id): Observable<any> {
    this.urls = path + "/articulos";

    var datoaEnviar = {
      "estado": 4,
      "sub_categoria_id": sub_categoria_id,
      "nombre": nombre,
      "sucursal_id": sucursal_id,
    }

    return this.http.post(this.urls, datoaEnviar, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  productosAlmacen(almacen_id, nombre): Observable<any> {
    this.urls = path + "/articulos";

    var datoaEnviar = {
      "almacen_id": almacen_id,
      "nombre": nombre,
    }

    return this.http.post(this.urls, datoaEnviar, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  //Conceptos de gastos
  getConceptogastos(): Observable<any> {
    this.urls = path + "/concepto-gastos";

    return this.http.get(this.urls, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  createConceptoGastos(descripcion): Observable<any> {
    this.urls = path + "/concepto-gastos/create";

    var datoaEnviar = {
      "descripcion": descripcion,
    }
    return this.http.post(this.urls, datoaEnviar, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  editConceptoGastos(descripcion, id): Observable<any> {
    this.urls = path + "/concepto-gastos/update/" + id;

    var datoaEnviar = {
      "descripcion": descripcion,
    }
    return this.http.put(this.urls, datoaEnviar, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  deleteConceptoGastos(id): Observable<any> {
    this.urls = path + "/concepto-gastos/delete/" + id;

    return this.http.delete(this.urls, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  //DetalleGasto
  getDetalleGastos(sucursal_id, gasto_id, fecha_inicio, fecha_fin, concepto_gasto_id): Observable<any> {
    this.urls = path + "/detalle-gastos?sucursal_id=" + sucursal_id + "&gasto_id=" + gasto_id +
      "&fecha_inicio=" + fecha_inicio + "&fecha_fin=" + fecha_fin + "&concepto_gasto_id=" + concepto_gasto_id;

    return this.http.get(this.urls, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  createDetalleGastos(monto, concepto_gasto_id, sucursal_id, gasto_id, comentario, numero_documento, tipo_documento): Observable<any> {
    this.urls = path + "/detalle-gastos/create";

    var datoaEnviar = {
      "monto": monto,
      "concepto_gasto_id": concepto_gasto_id,
      "sucursal_id": sucursal_id,
      "gasto_id": gasto_id,
      "detalle": comentario,
      "numero_documento": numero_documento,
      "tipo_documento": tipo_documento,
    }
    return this.http.post(this.urls, datoaEnviar, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }


  editDetalleGastos(monto, comentario, id, numero_documento, tipo_documento): Observable<any> {
    this.urls = path + "/detalle-gastos/update/" + id;

    var datoaEnviar = {
      "monto": monto,
      "detalle": comentario,
      "numero_documento": numero_documento,
      "tipo_documento": tipo_documento,
    }
    return this.http.put(this.urls, datoaEnviar, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }
  deleteDetalleGastos(id): Observable<any> {
    this.urls = path + "/detalle-gastos/delete/" + id;

    return this.http.delete(this.urls, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  getCountClientes(fecha_inicio, fecha_fin): Observable<any> {
    let params = new HttpParams();
    params = params.append('fecha_inicio', fecha_inicio);
    params = params.append('fecha_fin', fecha_fin);

    this.urls = path + "/clientes/count";

    return this.http.get<DataResponse>(`${this.urls}`, {
      headers: new HttpHeaders(environment.apiConfig.headers),
      reportProgress: true,
      params: params
    });
  }

  getCountPrestamos(fecha_inicio, fecha_fin): Observable<any> {
    this.urls = path + "/contratos/count";
    let params = new HttpParams();
    params = params.append('fecha_inicio', fecha_inicio);
    params = params.append('fecha_fin', fecha_fin);

    return this.http.get<DataResponse>(`${this.urls}`, {
      headers: new HttpHeaders(environment.apiConfig.headers),
      reportProgress: true,
      params: params
    });
  }

  getCountArticulos(fecha_inicio, fecha_fin): Observable<any> {
    this.urls = path + "/articulos/count";

    let params = new HttpParams();
    params = params.append('fecha_inicio', fecha_inicio);
    params = params.append('fecha_fin', fecha_fin);

    return this.http.get<DataResponse>(`${this.urls}`, {
      headers: new HttpHeaders(environment.apiConfig.headers),
      reportProgress: true,
      params: params
    });
  }

  getCountSnArticulos(fecha_inicio, fecha_fin): Observable<any> {
    this.urls = path + "/articulos/sn-count";

    let params = new HttpParams();
    params = params.append('fecha_inicio', fecha_inicio);
    params = params.append('fecha_fin', fecha_fin);

    return this.http.get<DataResponse>(`${this.urls}`, {
      headers: new HttpHeaders(environment.apiConfig.headers),
      reportProgress: true,
      params: params
    });
  }

  getCountVentas(id, fecha_inicio, fecha_fin): Observable<any> {
    this.urls = path + "/ventas/count/" + id;

    let params = new HttpParams();
    params = params.append('fecha_inicio', fecha_inicio);
    params = params.append('fecha_fin', fecha_fin);

    return this.http.get<DataResponse>(`${this.urls}`, {
      headers: new HttpHeaders(environment.apiConfig.headers),
      reportProgress: true,
      params: params
    });
  }

  createRegistroDiario(monto, comentario): Observable<any> {
    this.urls = path + "/registro-diario/create";

    var datoaEnviar = {
      "monto": monto,
      "comentario": comentario
    }
    return this.http.put(this.urls, datoaEnviar, httpHeaders)
      .pipe(
        tap((data: any) => {
          return of(data);
        }),
        catchError((err) => {

          return throwError(err);
        })
      );
  }

  searchVentas(nombre): Observable<any> {
    this.urls = path + "/contratos/ventas-art";

    let params = new HttpParams();
    params = params.append('nombre', nombre);

    return this.http.get<DataResponse>(`${this.urls}`, {
      headers: new HttpHeaders(environment.apiConfig.headers),
      reportProgress: true,
      params: params
    });
  }
}
